export const female = {
  Name: 'female',
  Quiz: {
    categories: [
      {
        maxIndex: 16,
        name: 'Profile',
      },
      {
        maxIndex: 36,
        name: 'Habits',
      },
      {
        maxIndex: 51,
        name: 'Health Conditions',
      },
    ],
    defaultProperties: [
      {
        question: 'gender',
        answer: 'female',
      },
    ],
    quizFlow: [
      {
        question: 'What is your weight loss goal?',
        questionCode: 'weight_loss_goal',
        answerGroups: ['LB', 'KG'],
        defaultAnswerGroup: 'LB',
        answers: (group: string) => {
          if (group === 'LB') {
            return [
              'Lose 1-20 lbs for good',
              'Lose 21-50 lbs for good',
              'Lose over 50 lbs for good',
              'Maintain weight and get fit',
              "I haven't decided yet",
            ];
          }

          return [
            'Lose 1-10 kg for good',
            'Lose 11-20 kg for good',
            'Lose over 20 kg for good',
            'Maintain weight and get fit',
            "I haven't decided yet",
          ];
        },
        type: 'question',
      },
      {
        type: 'input',
        heading: 'What is your age?',
        questionCode: 'age_input',
        button: 'Continue',
        param: 'age',
      },
      {
        type: 'input',
        heading: 'What is your height?',
        questionCode: 'height_input',
        button: 'Continue',
        param: 'height',
      },
      {
        type: 'input',
        heading: 'What is your current weight?',
        questionCode: 'current_weight_input',
        button: 'Continue',
        param: 'weight',
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'QuizBodyMassIndexInfo',
      },
      {
        type: 'input',
        heading: 'What is your desired weight?',
        questionCode: 'desired_weight_input',
        button: 'Continue',
        param: 'desired_weight',
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'ProgressStabilizer',
        bgColor: '#d2ffcd',
        headerVisible: false,
        args: ['goal'],
      },
      {
        type: 'input',
        heading: 'What is your name?',
        questionCode: 'name_input',
        button: 'Continue',
        param: 'name',
      },
      {
        question: 'How familiar are you with the Cortisol Detox Diet?',
        subtitle: 'We will adjust the program depending on your experience',
        questionCode: 'familiarity',
        answers: [
          '😊 Consider me as a beginner',
          '😎 I have some basic knowledge',
          '🧐 I am well experienced',
        ],
        type: 'question',
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'ProgressStabilizer',
        args: ['protein'],
      },
      {
        type: 'custom',
        image: 'none',
        args: ['renee'],
        componentId: 'QuizPromo',
      },
      {
        question: 'Which of the following best describes the area you live in?',
        questionCode: 'familiarity',
        answers: ['The countryside', 'A major city', 'Suburbs'],
        type: 'question',
      },
      {
        question:
          'Have you successfully lost weight before but failed to keep the results?',
        questionCode: 'familiarity',
        answers: ['Yes', 'No'],
        type: 'question',
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'ProgressStabilizer',
        bgColor: '#d2ffcd',
        headerVisible: false,
        args: ['different'],
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'LoadingBasicPlan',
      },
      {
        type: 'custom',
        image: 'none',
        headerVisible: false,
        componentId: 'QuizComplexSummary',
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'ProgressStabilizer',
        bgColor: '#d2ffcd',
        headerVisible: false,
        args: ['halfway'],
      },
      {
        question: "What's your typical week like?",
        questionCode: 'typical_week',
        answers: [
          '🛋 Not active<br /><span style="color:grey;font-size:12px;">Mostly sedentary</span>',
          '🚶 Lightly active<br /><span style="color:grey;font-size:12px;">Activities of daily living only</span>',
          '🏃 Moderately active<br /><span style="color:grey;font-size:12px;">Walking 1.5 to 3 miles daily</span>',
          '🚴‍♀️ Active<br /><span style="color:grey;font-size:12px;">Walking or exercising daily</span>',
        ],
        type: 'question',
      },
      {
        question: 'When was the last time you were at your ideal weight?',
        questionCode: 'ideal_weight',
        answers: [
          'Less than a year ago',
          '1 to 2 years ago',
          'More than 3 years ago',
          'Never',
        ],
        type: 'question',
      },
      {
        question: 'What size clothes do you usually wear?',
        questionCode: 'clothes_size',
        answers: ['XXXL+', 'XXXL', 'XXL', 'XL', 'L', 'M', 'S', 'XS', "I don't know"],
        type: 'question',
      },
      {
        question: 'What size clothes do you want to wear?',
        questionCode: 'clothes_size_goal',
        answers: ['XXXL+', 'XXXL', 'XXL', 'XL', 'L', 'M', 'S', 'XS', "I don't know"],
        type: 'question',
      },
      {
        type: 'custom',
        image: 'none',
        args: ['renee2'],
        componentId: 'QuizPromo',
      },
      {
        question: 'How would you describe your eating habits?',
        questionCode: 'eating_habits',
        answers: [
          'I eat the same food every day',
          'I eat similar foods, with some variations',
          'I rotate between familiar foods',
          'I like to experiment with different foods',
        ],
        type: 'question',
      },
      {
        question: 'How would you describe your cooking skills?',
        questionCode: 'eating_habits',
        answers: [
          'I am an expert cook',
          'I am learning to be a better cook',
          'I have no idea how to cook',
        ],
        type: 'question',
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'ProgressStabilizer',
        bgColor: '#d2ffcd',
        headerVisible: false,
        args: ['delicious'],
      },
      {
        question: 'What are your food preferences?',
        questionCode: 'food_preferences',
        answers: [
          'All in one',
          '🥑 Avocados',
          '🥚 Eggs',
          '🍄 Mushrooms',
          '🧅 Onions',
          '🧀 Cheese',
          '🌰 Nuts',
          '🧈 Butter',
          '🥥 Coconut',
          '🥛 Milk',
          '🦐 Seafood',
          '🍈 Olives',
          '🍢 Tofu',
          '🍚 Cottage cheese',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'Which meat do you prefer?',
        questionCode: 'meat_preferences',
        answers: [
          'All in one',
          '🐓 Chicken',
          '🐖 Pork',
          '🥓 Bacon',
          '🐂 Beef',
          '🦃 Turkey',
          '🐟 Fish',
          '🐑 Lamb',
          '🍆 I am a vegetarian/vegan',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'Do you have any food allergies or intolerance?',
        questionCode: 'food_allergies',
        answers: [
          'None',
          'Lactose',
          'Nuts',
          'Milk protein',
          'Egg protein',
          'Honey',
          'Fish',
          'Citrus fruits',
          'Prefer not to answer',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'How much water do you usually drink?',
        questionCode: 'daily_water_intake',
        answers: [
          {
            text: 'Only coffee or tea',
            image: '/static/images/coffee.svg',
            explainer: {
              title: '💧 Drinking water is essential',
              description:
                "If you're not hydrated, your body can't perform at its highest level. MediPlan will remind you to drink enough water.",
            },
          },
          {
            text: 'Less than 2 glasses (16 Oz)',
            image: '/static/images/water1.svg',
            explainer: {
              title: '💧 Drinking water is essential',
              description:
                "If you're not hydrated, your body can't perform at its highest level. MediPlan will remind you to drink enough water.",
            },
          },
          {
            text: '2-6 glasses (16-48 Oz)',
            image: '/static/images/water2.svg',
            explainer: {
              title: '💧 Wow! Impressive!',
              description: 'You drink more water than 75% of users*. Keep it up!',
              disclaimer: '*users of MediPlan who took the quiz.',
            },
          },
          {
            text: '7-10 glasses (56-80 Oz)',
            image: '/static/images/water3.svg',
            explainer: {
              title: '💧 Wow! Impressive!',
              description: 'You drink more water than 75% of users*. Keep it up!',
              disclaimer: '*users of MediPlan who took the quiz.',
            },
          },
          {
            text: "I don't count, it depends",
            image: '/static/images/dunno.svg',
          },
        ],
        type: 'question',
        isExplainerSelect: true,
      },
      {
        question: 'Is it hard for you to make healthy choices during the weekend?',
        questionCode: 'weekend_healthy_choices',
        answers: ['Yes', 'No'],
        type: 'question',
      },
      {
        question: 'Do you typically eat meals around the same time each day?',
        questionCode: 'meal_time_variability',
        answers: ['Yes', 'No'],
        type: 'question',
      },
      {
        question: 'How would you rate the quality of your sleep?',
        questionCode: 'sleep_quality',
        image: 'none',
        answers: [
          'I have trouble falling asleep',
          'It varies',
          'I sleep well most nights',
        ],
        type: 'question',
      },
      {
        question: 'What’s your work schedule?',
        questionCode: 'work_schedule',
        answers: [
          'Flexible',
          'Nine to five',
          'Shifts',
          'Between jobs or unemployed',
          'Seasonal',
          'Retired',
          'Other',
          'Prefer not say',
        ],
        type: 'question',
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'QuizComplexSummary',
        args: ['erase-week'],
        headerVisible: false,
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'QuizEmailForm',
        headerVisible: false,
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'ProgressStabilizer',
        bgColor: '#d2ffcd',
        headerVisible: false,
        args: ['almost_done'],
      },
      {
        question: 'Are you taking any medications?',
        questionCode: 'medications_taken',
        answers: [
          'Vitamins',
          'Hormones',
          'Antibiotics',
          'Anxiety Medications',
          'Other',
          'No',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'Do you have any dietary restrictions?',
        questionCode: 'dietary_restrictions',
        answers: ['Yes', 'No'],
        type: 'question',
      },
      {
        question: 'Are you at risk of any of the following?',
        questionCode: 'health_risks',
        answers: [
          'High blood pressure',
          'Heart disease or stroke',
          'High cholesterol',
          'Diabetes',
          'Osteoarthritis',
          'Kidney disease',
          'Depression',
          'None of these',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'ProgressStabilizer',
        bgColor: '#d2ffcd',
        headerVisible: false,
        args: ['sharing_gratitude'],
      },
      {
        question: '"My weight impacts my self-esteem"',
        subtitle: 'Do you agree with the statement above?',
        questionCode: 'self_esteem_impact',
        answers: ['Yes', 'No'],
        type: 'question',
      },
      {
        question: '"My family is concerned about my health"',
        subtitle: 'Do you agree with the statement above?',
        questionCode: 'family_concerns',
        answers: ['Yes', 'No'],
        type: 'question',
      },
      {
        question: '"I am extremely motivated to reach my target weight"',
        subtitle: 'Do you agree with the statement above?',
        questionCode: 'motivation_to_reach_target',
        answers: ['Yes', 'No'],
        type: 'question',
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'ProgressStabilizer',
        bgColor: '#d2ffcd',
        headerVisible: false,
        args: ['motivation'],
      },
      {
        question: 'Do you plan to start dieting on your own?',
        questionCode: 'start_plan',
        answers: [
          'On my own',
          'With a friend',
          'With a family member',
          'On my own, but I would like to connect with someone',
        ],
        type: 'question',
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'ProgressStabilizer',
        bgColor: '#d2ffcd',
        headerVisible: false,
        args: ['goals_reality'],
      },
      {
        question: 'What goal would you like to achieve in addition to weight loss?',
        questionCode: 'additional_goal',
        answers: [
          'Run a 5k',
          'Look better in clothes',
          'Feel healthier and increase energy',
          'Reverse a chronic health condition',
          'Sleep well',
          'Prepre myself for a special event',
          'Feel more comfortable in my body',
          'Be able to do outdoor activities',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'QuizPromo',
        args: ['face_change'],
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'LoadingCustomizedPlan',
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'ProgressStabilizer',
        bgColor: '#d2ffcd',
        headerVisible: false,
        args: ['experts'],
      },
      {
        type: 'custom',
        image: 'none',
        componentId: 'QuizComplexSummary',
        args: ['finalized'],
        headerVisible: false,
      },
    ],
  },
};
